import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/messaging';


const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

const settings = {
    timestampsInSnapshots: true
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.firestore = app.firestore();
        this.firestore.settings(settings);

        this.db = app.database();

        this.storage = app.storage();

        this.state = {user: null}
    }


    ////////////////    AUTH API    ////////////////

    /**
     *  Adds a new user to Firebase Auth API
     */
    doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password).then(authUser => {
        return authUser;
    }).catch(error => {
        return error;
    })

    doCreateUser = (uid, email, subType) => this.firestore.collection('users').doc(uid).set({
        email: email,
        profileImage: 'https://images.pexels.com/photos/6347/coffee-cup-working-happy.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        subscriptionType: subType,
        children: []
    }).then(userRef => {
        return userRef;
    }).catch(error => {
        console.error(error);
        return 'error';
    });


    /**
     * Delete User from Firebase Authentication API.
     * A Firebase Functions catches this event and deletes the rest of
     * the user's data and Stripe data.
     */
    doDeleteUser = () => this.auth.currentUser.delete().then(() => {
        // user deleted
    }).catch(error => {
        console.error(error);
        return 'error';
    });

    
    /**
     * Sign in to app using Firebase API
     */
    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password).then(authUser => {
        return authUser;
    }).catch(error => {
        return error;
    });


    /**
     * Sign the current user out of Firebase
     */
    doSignOut = () => this.auth.signOut().then(() => {
        return 'Successfully signed out!';
    }).catch(error => {
        console.error(error);
    });


    /**
     * Send reset password email to current user
     */
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email).then(() => {
        return 'Successfully sent email!';
    }).catch(error => {
        console.error(error);
    });


    /**
     * Update password for currently logged in user
     */
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password).then(() => {
        return 'Successfully updated password';
    }).catch(error => {
        console.error(error);
    });




    ////////////////    USER API    ////////////////

    user = uid => this.firestore.collection('users').doc(uid).get();

    /**
     * Get user object from database with matching uid
     */
    getUserById = uid => this.firestore.collection('users').doc(uid).get().then((doc) => {
        return doc.data();
    }).catch(error => {
        console.error(error);
    });


    /**
     * Retrieve the child object from database with ID that matches child_id
     */
    getChildById = child_id => this.firestore.collection('children').doc(child_id).get().then((doc) => {
        return doc.data()
    }).catch(error => {
        console.error(error);
    });


    /**
     * Upload an image to Firebase
     */
    uploadPhoto = filename => this.storage.ref('childImages').child(filename).getDownloadURL().then(url => {
        return url;
    }).catch(error => {
        console.error(error);
        return 'error';
    });


    /**
     * Add a new child to the database and return the new child's ID.
     * @param student The student object containing name, grade, and image url
     */
    doAddChild = student => this.firestore.collection('children').add({
        grade: student.grade,
        name: student.name,
        image: student.url,
        results: '',
        pastResults: []
    }).then((docRef) => {
        return docRef.id;
    }).catch(error => {
        console.error(error);
        return false;
    });

    /**
     * Add a child ID to a user's children array.
     * @param parent_id The user's uid
     * @param child_id The child's document reference id
     */
    doAddChildToParent = (parent_id, child_id) => this.firestore.collection('users').doc(parent_id).update({
        children: app.firestore.FieldValue.arrayUnion(child_id)
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });


    doDeleteChild = studentId => this.firestore.collection('children').doc(studentId).delete().then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });


    doRemoveChildFromParent = (parent_id, child_id) => this.firestore.collection('users').doc(parent_id).update({
        children: app.firestore.FieldValue.arrayRemove(child_id)
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });

    
    doUpdateUserPhoto = newUrl => this.firestore.collection('users').doc(this.auth.currentUser.uid).update({
        profileImage: newUrl
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });

    
    getStripeCustomer = uid => this.db.ref('/stripe_customers/' + uid).once('value').then((snapshot) => {
        return snapshot;
    }).catch(error => {
        console.error(error);
    });





    ////////////////    TEST API    ////////////////


    /**
     * Retrieve the test specified by test type (i.e. PMA or any of the specific diagnostics).
     */
    doGetTestByType = testType => this.firestore.doc('tests/' + testType).get().then(doc => {
        return doc.data();
    }).catch(error => {
        console.error(error);
        return 'error';
    });


    /**
     * Retrieve a student's screener result, specified by ID
     */
    getResultById = result_id => this.firestore.doc('results/' + result_id).get().then((doc) => {
        return doc.data();
    }).catch(error => {
        console.error(error);
        return 'error';
    });


    /**
     * Creates a new result object in the results collection.
     */
    doCreateResult = resultObject => this.firestore.collection('results').add(resultObject).then(ref => {
        return ref;
    }).catch(error => {
        console.error(error);
        return 'error';
    });

    
    /**
     * Update the scoreBreakDown object in the specified results object.
     * Called every time the user presses the 'next' button during a quiz.
     */
    doUpdateResultScore = (scoreBreakDownObject, result_id) => this.firestore.collection('results').doc(result_id).update({
        scoreBreakDown: scoreBreakDownObject
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });


    /**
     * Updates the 'results' field for the specified child object. This method is also used
     * to clear the field by passing an empty string for result_id.
     */
    doUpdateChildResults = (child_id, result_id) => this.firestore.collection('children').doc(child_id).update({
        results: result_id
    }).catch(error => {
        console.error(error);
    })


    /**
     * Updates the current slide in the slide field for the specified result object.
     */
    doUpdateResultSlide = (slideNumber, result_id) => this.firestore.collection('results').doc(result_id).update({
        slide: slideNumber
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });


    /**
     * Add a new result object to a child.
     */
    doAddResultToChild = (child_id, result_id) => this.firestore.doc('children/' + child_id).update({
        results: result_id
    }).then(() => {
        return true;
    }).catch(error => {
        console.error(error);
        return false;
    });


    /**
     * Update the pastResults array for the specified child object.
     */
    putResultsInPastResults = (result_id, child_id) => this.firestore.collection('children').doc(child_id).update({
            pastResults: app.firestore.FieldValue.arrayUnion(result_id)
    }).catch(error => {
        console.error(error);
    });



    /**
     * Sets the isComplete field for the specified result object.
     */
    setResultIsComplete = (result_id, isComplete) => this.firestore.collection('results').doc(result_id).update({
        isComplete: isComplete
    }).catch(error => {
        console.error(error);
    });


    /**
     * Retrieves the image URL for image.
     */
    doRetrieveImage = image => this.storage.ref(image).getDownloadURL().then(url => {
        return url;
    }).catch(error => {
        console.error(error);
        return 'error';
    });


    ////////////// STRIPE API //////////////

    addStripeTokenToFirebase = (token, user_id) => this.firestore.collection('stripe_customers').doc(user_id).collection('tokens').add({token: token}).then(() => {
        return 'success';
    }).catch(error => {
        console.error(error);
        return 'error';
    });

}

export default Firebase;

import React from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import ChildTile from '../ChildTile';
import FAB from '../FloatingActionButton';
import AddChildForm from '../ManageChildren/AddChildForm';
import './Dashboard.scss';

import * as $ from 'jquery';

const MATH_PACK_CHARACTERS = require('../../images/math_pack_characters_only.png');

const URL = process.env.REACT_APP_DRUPAL_URL;

const DashboardPage = () => (
    <div className="container">
        <div className="row">
            <div className="col">
                <AuthUserContext.Consumer>
                    {authUser => (
                        <DashboardPageData authUser={authUser} />
                    )}
                </AuthUserContext.Consumer>
                
            </div>
        </div>
    </div>
);

const INITIAL_STATE = {
    user: null,
    newUser: false,
    children: null,
    resources: null
};


class DashboardPageBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};

        this.onChildAdded = this.onChildAdded.bind(this);
    }

    componentDidMount() {
        if (window.location.href.indexOf('new') > -1) {
            this.setState({newUser: true});
        }
        this.props.firebase.getUserById(this.props.authUser.uid).then((userResult) => {
            this.setState({user: userResult});
            this.getChildren(userResult);
        });
        this.getResources();
    }

    onChildAdded() {
        $('#addStudentModal').modal('hide');
        window.location.reload();
    }


    render() {
        if (this.state.children) {
            return(
                <div>
                    <h1 className="page-header">Overview</h1>

                    {this.state.newUser &&
                    <div className="new-user-alert">
                        <button type="button" className="close" aria-label="Dismiss" onClick={() => {this.setState({newUser: false})}}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h1>SUCCESS</h1>
                        <h2>Welcome to your Dashboard!</h2>
                        <div className="text-left">
                            <p>This page gives you a high-level overview of your child/children's performance.</p>
                            <p>After your child has taken a <strong>Primary Math Assessment (PMA) Screener</strong>, or a <strong>Diagnostic</strong> focusing on a single dimension, the results will be displayed here along with a link to more detailed result data.</p>
                            <p>If a child is struggling in a certain dimension, their section will have <strong>recommended activities</strong> they can do to help strengthen that area. You can find all available resources by choosing "Activities & Resources" in the navigation menu.</p>
                            <p>Click on your avatar in the navigation bar to view/edit your account details or to sign out.</p>
                            <p>Follow the instructions below to <strong>add a child</strong> and get started!</p>
                        </div>
                    </div>}

                    {this.state.children && this.state.children.length > 0 &&
                    this.renderChildTiles()}

                    {this.state.children && this.state.children.length === 0 &&
                    <div>
                        <p>You have not added any children to your account. Use the form below to get started.</p>
                        <div className="card">
                        <div className="card-body">
                        <h2>Add Child</h2>
                            <AddChildForm onChildAdded={this.onChildAdded} />
                            </div>
                        </div>
                    </div>}

                    <div className="card math-pack-tile">
                        <div className="card-body">
                            <div className="mp-banner negative-margin">
                                <span className="mr-3">MATH</span>
                                <img src={MATH_PACK_CHARACTERS} className="math-pack-characters d-sm-block d-none" alt="math pack characters"/>
                                <span className="ml-3">PACK</span>
                            </div>
                            <h2 className="mp-header">DMTI Math Pack</h2>
                            <p>
                                The DMTI Math Pack ensures you have all the tools you need to complete all of the official DMTI Activities.
                                Get the most out of the Complete Math Program with the Advanced Math Pack, which includes every item you
                                need to do all activities. The Basic Math Pack will provide you with just the essentials that will still
                                allow you to do many of the DMTI Activities.
                            </p>
                            <a href="https://www.dmtinstitute.com/form/buy-math-pack" className="btn btn-primary d-inline-block" role="button" target="_blank" rel="noopener noreferrer">Order Now</a>
                        </div>
                    </div>

                    <FAB modal_id={'#addStudentModal'} />

                    {/* Add Child Modal */}
                    <div className="modal fade" id="addStudentModal" tabIndex="-1" role="dialog" aria-labelledby="addChildModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Add Child</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <AddChildForm onChildAdded={this.onChildAdded}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }else {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <FontAwesomeIcon icon="spinner" spin className="loading-spin"/>
                            <p className="mt-3 text-primary">Loading...</p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    /**
     *  Retrieve Resources from Drupal endpoint
     */
    getResources() {
        let options = {
            method: "GET",
            headers: {
                "Content-Type": "text/plain"
            },
            mode: 'cors',
        }
        fetch(URL + "/rest/export/json", options).then(res => res.json()).then((resources) => {
                this.setState({resources: resources});
        },(error) => {
            console.error(error);
        });
    }


    getChildren(user) {
        let childObjects = [];
        let children = user.children;
        if (children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                let child_id = children[i];
                // If this causes problems call firebase directly instead of going through API method.
                this.props.firebase.getChildById(child_id).then((child) => {
                    let newChildObject = {
                        id: child_id,
                        data: child
                    };
                    childObjects.push(newChildObject);
                    this.setState({children: childObjects});
                });
            }
        }else {
            this.setState({children: children});
        }
    }

    renderChildTiles() {
        let markup = [];
        this.state.children.forEach((child) => {
               markup.push(<ChildTile child={child} key={child.data.name} />);
        });
        return markup;
    }
}

// check whether or not we can show the dashboard page
const condition = authUser => !!authUser;

export default withAuthorization(condition)(DashboardPage);

const DashboardPageData = compose(
    withRouter,
    withFirebase
)(DashboardPageBase);

export { DashboardPageData };
import React from 'react';
import ChildResult from '../Result/ChildResult';
import Avatar from '../Avatar';
import * as _ from 'underscore';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {withFirebase} from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ChildTile.scss';

class ChildTile extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {results: null, testInProgress: null}
    }


    componentDidMount() {
        if (this.props.child.data.results !== undefined && this.props.child.data.results !== '') {
            this.getTestInProgressForCurrentChild(this.props.child.data.results);
        }
        this.getPastResultsForCurrentChild(this.props.child.data);
    }


    render() {
        return (
            <div className="card child-card">
                <div className="d-flex align-items-center mb-3">
                    <Avatar photoUrl={this.props.child.data.image} size="md" />
                    <div className="ml-2">
                        <h2 className="child-name m-0">{this.props.child.data.name}</h2>
                        <p className="childtile-grade m-0">
                            {this.props.child.data.grade !== 'Kindergarten' ?
                            this.props.child.data.grade + ' Grade' :
                            this.props.child.data.grade}
                        </p>
                    </div>
                </div>

                {this.state.testInProgress &&
                <div className="child-result" key={this.props.child.data.name + 'no-results'}>
                    <p>
                        <FontAwesomeIcon icon="exclamation-triangle" className="mr-2 text-warning" />
                        {this.props.child.data.name} has a {this.state.testInProgress.isDiagnostic ? 'Diagnostic' : 'Screener'} in progress. Click below to resume.
                    </p>
                    <Link to={ROUTES.SCREENER + '/' + this.props.child.id + '/' + this.state.testInProgress.test + '/' + this.props.child.data.results} >
                        <button type="button" className="btn btn-primary">
                            <FontAwesomeIcon icon="clipboard-list" className="mr-2" />
                            Resume {this.state.testInProgress.isDiagnostic ? 'Diagnostic' : 'Screener'}
                        </button>
                    </Link>
                </div>}

                {this.state.results && (this.state.results.length === this.props.child.data.pastResults.length) &&
                this.renderResults()}
            </div>
        );
    }



    /**
     * Renders each result summary for the child
     */
    renderResults() {
        let markup = [];
        if (this.props.child.data.pastResults.length === 0) {
            if (!this.state.testInProgress) {
                markup.push(
                    <div className="child-result" key={this.props.child.data.name + 'no-results'}>
                        <p>
                            <FontAwesomeIcon icon="exclamation-circle" className="mr-2 text-muted" />
                            {this.props.child.data.name} has no test results. Get started by taking a screener!
                        </p>
                        <Link to={ROUTES.SCREENER + '/' + this.props.child.id + '/PMA-w-2018'} >
                            <button type="button" className="btn btn-primary">
                                <FontAwesomeIcon icon="clipboard-list" className="mr-2" />
                                Start Screener
                            </button>
                        </Link>
                    </div>
                )
            }
        }else {
            this.state.results.forEach((result) => {
                markup.push(
                    <ChildResult key={this.props.child.data.name + result.id} result={result} child={this.props.child} />
                );
            });
            markup.push(
                <Link to={ROUTES.SCREENER + '/' + this.props.child.id + '/PMA-w-2018'} key={'screenerButton-' + this.props.child.id} >
                    <button type="button" className="btn btn-primary">
                        <FontAwesomeIcon icon="clipboard-list" className="mr-2" />
                        Start Screener
                    </button>
                </Link>
            )
        }
        return markup;
    }



    /**
     * Retrieve all of the child's past results from the DB
     * @param {Object} child 
     */
    getPastResultsForCurrentChild(child) {
        let results = [];

        if (child.pastResults.length === 0) {
            this.setState({results: results});
        }else {
            for (let i=0; i < child.pastResults.length; i++ ) {
                let result_id = child.pastResults[i];

                this.props.firebase.getResultById(result_id).then((resultResponse) => {
                    let tempResult = _.clone(resultResponse);
                    tempResult['id'] = result_id;
                    results.push(tempResult);
                    this.setState({results: results});
                });
            }
        }
    }


    getTestInProgressForCurrentChild(result_id) {
        this.props.firebase.getResultById(result_id).then((resultResponse) => {
            if (resultResponse !== 'error') {
                this.setState({ testInProgress: resultResponse });
            } 
        });
    }
}

export default withRouter(withFirebase(ChildTile));
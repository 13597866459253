import React, {Component} from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import mainLogo from '../../images/logo_banner.png';
import './Login.scss';


const LoginPage = () => (
    <div className="container">
        <div className="row">
           
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-12">
                <img src={mainLogo} className="login-logo" alt="DMTI Logo" />
                <h2 className="brand-text">Math Improvement. Life Improvement.</h2>
                <div className="card login-card">
                    <h1>Login</h1>
                    <AuthUserContext.Consumer>
                        {authUser => (
                            <LoginForm authUser={authUser}/>
                        )}
                    </AuthUserContext.Consumer>
                </div>
            </div>
        </div>
    </div>
);


const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};


class LoginPageBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }


    /**
     * Called when user presses 'submit' button on the login form.
     * Uses the Firebase API to sign in.
     */
    onSubmit = (event) => {
        event.preventDefault();

        const { email, password } = this.state;

        this.props.firebase.doSignInWithEmailAndPassword(email, password).then((res) => {
            if (res.message) {
                this.setState({ error: res.message });
            }else {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push('/dashboard');
            }
        });
    };


    /**
     * Change handler for form fields
     */
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
   


    render() {

        // If already logged in, go to dashboard
        if (this.props.authUser != null) {
            this.props.history.push('/dashboard');
        }
        
        const { email, password, error } = this.state;
        const isInvalid = password === '' || email === '';

        return(

            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <label htmlFor="email" type="hidden"></label>
                    <input name="email" className="form-control" value={email} onChange={this.onChange} type="text" placeholder="Email Address" />
               
                    <label htmlFor="password" type="hidden"></label>
                    <input name="password" className="form-control" value={password} onChange={this.onChange} type="password" placeholder="Password" />
                </div>

                <div className="d-flex">
                </div>
                <button className="btn btn-primary" disabled={isInvalid} type="submit">Sign In</button>

                <Link className="reset-link" to={ROUTES.PASSWORD_RESET}>Reset Password</Link>

                <div className="signup-block">
                    <span>Don't have an account? </span>
                    <Link to={ROUTES.SIGN_UP}>Sign up</Link>
                </div>

                {error &&
                <div className="alert alert-danger mt-3" role="alert">
                    <span>{error}</span>
                </div>}
                
            </form>
        );
    }
}



const LoginForm = compose(
    withRouter,
    withFirebase,
)(LoginPageBase);

export default LoginPage;

export { LoginForm };
import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { AuthUserContext, withAuthorization } from '../Session';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import * as ROUTES from '../../constants/routes';
import * as _ from 'underscore';

import ScreenerQuestion from './ScreenerQuestion';

import './Screener.scss';


const ScreenerPage = ({test_type, child_id, result_id}) => (
    <div>
        <div className="container">
            <div className="row">
                <div className="col">
                    <h1 className="page-header">{test_type === 'PMA-w-2018' ? 'Screener' : 'Diagnostic'}</h1>
                </div>
            </div>
        </div>
        <AuthUserContext.Consumer>
            {authUser => (
                <ScreenerQuiz testType={test_type} childId={child_id} authUser={authUser} resultId={result_id}/>
            )}
        </AuthUserContext.Consumer>
    </div>
);


class ScreenerQuizBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0,
            currentQuestionTotalValue: -1,
            currentSlide: {},
            currentSlideImage: '',
            child: null,
            quiz: null,
            resultId: null,
            nextIsValid: true,
            scoreBreakDown: {}
        };

        this.nextSlide = this.nextSlide.bind(this);
        this.selectAnswer = this.selectAnswer.bind(this);
    }


    componentDidMount() {
        this.props.firebase.getChildById(this.props.childId).then(childResponse => {
            
            let quizGrade = childResponse.grade.toLowerCase();

            if (quizGrade === 'other' || quizGrade === '') {
                quizGrade = '2nd';
            }

            this.setState({ child: childResponse });

            this.props.firebase.doGetTestByType(this.props.testType).then(quizResponse => {
                let isDiagnostic = this.props.testType !== 'PMA-w-2018';
                let quiz = JSON.parse(quizResponse[quizGrade]);
                this.setState({
                    quiz: quiz,
                    isDiagnostic: isDiagnostic
                });

                // If user is resuming a quiz
                if (this.props.resultId) {
                    this.props.firebase.getResultById(this.props.resultId).then(result => {
                        let currSlide = _.clone(quiz.slides[result.slide]);
                        this.processText(currSlide);

                        let currentQuestionTotalValue = -1;
                        let diagnosticNextIsValid = true;

                        for (let i=0; i<currSlide.answerOptions.length; i++) {
                            let valueAsFloat = Number.parseFloat(currSlide.answerOptions[i].valueRasch);
                            if (valueAsFloat > 0) {
                                currentQuestionTotalValue = valueAsFloat
                                break;
                            }
                        }

                        if (currSlide.questionType === 'image' || (currSlide.type === 'question' && currSlide.image !== '')) {
                            this.getImage(currSlide.image);
                        }

                        this.setState({
                            nextIsValid: currSlide.type !== 'question',
                            diagnosticNextIsValid: diagnosticNextIsValid,
                            currentIndex: result.slide,
                            currentSlide: currSlide,
                            resultId: this.props.resultId,
                            scoreBreakDown: _.clone(result.scoreBreakDown),
                            currentQuestionTotalValue: currentQuestionTotalValue
                        });
                    });
                } else {
                    // Otherwise, create new results in the database
                    this.createResults();
                    let currSlide = _.clone(quiz.slides[0]);
                    this.processText(currSlide);
                    this.setState({
                        currentSlide: currSlide
                    });
                }
            });
        });
    }


    render() {
        return (
            <div className="text-center pl-4 pr-4">
                {/* Question Contents */}
                <ScreenerQuestion slide={this.state.currentSlide} selectAnswer={this.selectAnswer} currentSlideImage={this.state.currentSlideImage} />
                <div className="container-fluid">
                    <div className="row">
                        <div className={this.state.currentSlide.type === 'question' ? 'col-12 col-lg-4 offset-lg-8 text-center p-0' : 'col-12 col-lg-4 offset-lg-4 text-center p-0'}>
                            {/* Next Slide Button */}
                            {this.state.quiz &&
                            <button type="button" className="btn btn-primary mt-3 w-100" onClick={this.nextSlide} disabled={!this.state.nextIsValid}>
                                {this.state.currentIndex === 999 ? 'Finish' : 'Next'}
                                <FontAwesomeIcon icon="arrow-right" className="ml-2" />
                            </button>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }



    /**
     * Get's the image URL from Firebase
     * @param {string} image Path of the image in firebase
     */
    getImage(image) {
        this.props.firebase.doRetrieveImage(image).then(imageResponse => {
            if (imageResponse === 'error') {
                this.setState({ currentSlideImage: '/images/broken_image.gif' });
            }else {
                this.setState({ currentSlideImage: imageResponse })
            }
        });
    }



    /**
     * Creates a new results object
     */
    createResults() {
        let resultObj = {
            country: 'United States',
            grade: this.state.child.grade,
            score: 0,
            slide: 0,
            state: 'ID',
            test: this.props.testType,
            isComplete: false,
            isDiagnostic: this.props.testType !== 'PMA-w-2018',
            scoreBreakDown: {},
            parent: this.props.authUser.uid
        };
        this.props.firebase.doCreateResult(resultObj).then(resultResponse => {
            if (resultResponse !== 'error') {
                this.setState({resultId: resultResponse.id})
                this.props.firebase.doAddResultToChild(this.props.childId, resultResponse.id);
            }
        });
    }



    /**
     * Prepares the selected answer for saving
     * @param {string} value The answer's value
     * @param {string} subCategory The subcategory the answer belongs to
     */
    selectAnswer(value, subCategory) {
        let valueAsFloat = Number.parseFloat(value);
        this.setState({
            currentSelectedValue: valueAsFloat,
            currentSubCategory: subCategory,
            nextIsValid: true
        });
    }



    /**
     * Updates the local state of the current quiz score
     * @param {string} valueRasch The amount the answer is worth
     * @param {string} subCategory The dimension to add the score to
     */
    saveAnswer(callback) {

        let subCategory = this.state.currentSubCategory;
        let newScoreBreakDown = _.clone(this.state.scoreBreakDown);

        let value = this.state.currentSelectedValue;

        let possibleValue = this.state.currentQuestionTotalValue;

        if (newScoreBreakDown === undefined || newScoreBreakDown[subCategory] === null || newScoreBreakDown[subCategory] === undefined) {
            newScoreBreakDown[subCategory] = { possible: 0, earned: 0 }
            newScoreBreakDown[subCategory].possible = possibleValue;
            newScoreBreakDown[subCategory].earned = value;
        }else {
            newScoreBreakDown[subCategory].possible += possibleValue;
            newScoreBreakDown[subCategory].earned += value;
        }

        this.setState({
            scoreBreakDown: newScoreBreakDown
        }, () => {
            return callback();
        });
    }



    /**
     * Sets the result's isComplete flag to 'true' in the database,
     * and then moves the result ID to the pastResults array in the child's database object.
     */
    finishQuiz() {
        this.props.firebase.setResultIsComplete(this.state.resultId, true);
        this.props.firebase.putResultsInPastResults(this.state.resultId, this.props.childId);
        this.props.firebase.doUpdateChildResults(this.props.childId, "");
        this.showFinalSlide();
    }



    /**
     * Shows a final slide to indicate quiz is over
     */
    showFinalSlide() {
        let finalSlide = {
            id: 9999,
            text: 'All done!',
            subText: 'You have completed this ' + (this.state.isDiagnostic ? 'Diagnostic' : 'Screener') +'. Click "Finish" to view your results.',
            type: 'final'
        };

        this.setState({
            currentSlide: finalSlide,
            currentIndex: 999
        });
    }



    /**
     * Handler for hitting the 'next' button. Sets the current slide
     * to the next slide in the quiz. Updates the slide for the result object in DB.
     * If just starting quiz, it creates results object.
     * If finishing quiz, it calls the finishQuiz function.
     */
    nextSlide() {
        let isValid = true;
        let currentIndex = this.state.currentIndex;

        if (currentIndex === 999) {
            this.props.history.push(ROUTES.DETAILS + '/' + this.props.childId + '/' + this.state.resultId);
        } else if (currentIndex <= this.state.quiz.slides.length - 1) {

            if (this.state.currentSlide.type === 'question') {
                this.saveAnswer(() => {
                    this.props.firebase.doUpdateResultScore(this.state.scoreBreakDown, this.state.resultId);
                });
            }

            // If we're at the end, wrap up the quiz. Otherwise, continue to next slide
            if (currentIndex === this.state.quiz.slides.length - 1) {
                this.finishQuiz();
            } else {
                let newIndex = currentIndex + 1;
                let newSlide = _.clone(this.state.quiz.slides[newIndex]);

                this.props.firebase.doUpdateResultSlide(newIndex, this.state.resultId);

                // Remove weird stuff from slide text fields
                this.processText(newSlide);

                let currentQuestionTotalValue = -1;
    
                // Load an image if we need to
                if (newSlide.type === 'question') {

                    isValid = false;

                    for (let i=0; i<newSlide.answerOptions.length; i++) {
                        let valueAsFloat = Number.parseFloat(newSlide.answerOptions[i].valueRasch);
                        if (valueAsFloat > 0) {
                            currentQuestionTotalValue = valueAsFloat
                            break;
                        }
                    }
                    
                    if (newSlide.questionType === 'image' || (newSlide.type === 'question' && newSlide.image !== '')) {
                        this.getImage(newSlide.image);
                    }
                }

                if (this.state.isDiagnostic) {
                    this.setState((state, props) => ({
                        currentSlide: newSlide,
                        currentIndex: newIndex,
                        nextIsValid: isValid,
                        diagnosticNextIsValid: isValid,
                        currentQuestionTotalValue: currentQuestionTotalValue
                    }));
                }else {
                    this.setState((state, props) => ({
                        currentSlide: newSlide,
                        currentIndex: newIndex,
                        nextIsValid: isValid,
                        currentQuestionTotalValue: currentQuestionTotalValue
                    }));
                }
            }
        } 
    }



    updateCurrentSlideInDatabase(slideNumber) {
        this.props.firebase.doUpdateSlideNumber(slideNumber)
    }



    /**
     * Removes unecessary characters from the slide text
     * @param {Object} slide The slide object
     */
    processText(slide) {
        if (slide.type === 'cover') {
            slide.text = slide.text.replace(' % $grade %', ': ' + this.state.child.grade);
            slide.subText = slide.subText.replace('% $grade %', this.state.child.grade);
            slide.subText = slide.subText.replace('% $semester %', '');
        } else if (slide.type === 'question' && slide.questionType === 'image') {
            slide.text = slide.text.replace('% image %', '');
        }
    }
}

const condition = authUser => !!authUser;

const ScreenerQuiz = compose(
    withRouter,
    withFirebase
)(ScreenerQuizBase);

export default withAuthorization(condition)(ScreenerPage);

export { ScreenerQuiz };
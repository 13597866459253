import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FloatingActionButton.scss';

const FAB = ({modal_id}) => (
    <button type="button" className="btn btn-primary dmti-fab" data-toggle="modal" data-target={modal_id}>
        <FontAwesomeIcon icon="plus" />
    </button>
);

export default FAB;
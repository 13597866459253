import React from 'react';
import { withFirebase } from '../../Firebase';
import { AuthUserContext } from '../../Session';
import { compose } from 'recompose';
import FileUploader from 'react-firebase-file-uploader';
import LoadingBar from '../../LoadingBar';

import './AddChildForm.scss';

const INITIAL_STATE = {
    name: '',
    grade: '',
    photo: '',
    isUploading: false,
    error: null
};

const AddChildForm = ({onChildAdded}) => (
    <AuthUserContext.Consumer>
        {authUser => (
            <AddChild authUser={authUser} onChildAdded={onChildAdded} />
        )}
    </AuthUserContext.Consumer>
);


class AddChildFormBase extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    /**
     * Change handler for form fields
     */
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onSubmit = event => {
        this.addChild();
        event.preventDefault();
    }

    handleUploadStart = () => this.setState({
        isUploading: true,
    });

    handleUploadError = error => {
        this.setState({
            isUploading: false,
            error: error.message
        });
    };


    /**
     * Check for image upload success and handle accordingly
     */
    handleUploadSuccess = filename => {
        this.setState({
            isUploading: false,
        });
        this.props.firebase.uploadPhoto(filename).then(url => {
            if (url === 'error') {
                this.setState({
                    error: 'There was a problem uploading the photo. Please refresh and try again.'
                });
            }else {
                this.setState({
                    photo: url
                });
            }
        });
    };

    
    /**
     * Grab information and add child to Firebase
     */
    addChild() {
        let url = this.state.photo;

        if (url === '') {
            url = 'https://images.pexels.com/photos/6347/coffee-cup-working-happy.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260';
        }
       
        this.props.firebase.doAddChild({name: this.state.name, grade: this.state.grade, url: url}).then((childId) => {
            if (childId) {
                this.props.firebase.doAddChildToParent(this.props.authUser.uid, childId).then((result) => {
                    if (result) {
                        this.props.onChildAdded();
                    }else {
                        this.setState({
                            error: 'Error while adding child. Please refresh and try again'
                        });
                        // Child was added to DB but not to user which will cause issues. remove from DB.
                        this.props.firebase.doDeleteChild(childId);
                    }
                });
            }else {
                this.setState({
                    error: 'Error while adding child. Please refresh and try again.'
                });
            }
        });
    }



    render() {
        const { name, grade, error } = this.state;
        const isInvalid = name === '' || grade === '';
        return (
            <form name="addChildForm" className="pt-3 pb-3" onSubmit={this.onSubmit}>
                <div className="form-group">
                    <label htmlFor="nameInput">Name</label>
                    <input name="name" type="text" className="form-control" id="nameInput" aria-describedby="childName" placeholder="Enter name" onChange={this.onChange} value={name}/>
                </div>

                <div className="form-group">
                    <label htmlFor="gradeSelect">Grade</label>
                    <select name="grade" className="form-control" id="gradeSelect" value={grade} onChange={this.onChange}>
                        <option value="" disabled>Choose grade</option>
                        <option value="Kindergarten">Kindergarten</option>
                        <option value="1st">1st</option>
                        <option value="2nd">2nd</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="form-group">
                    <label className="d-block" htmlFor="avatarUploader">Avatar (optional)</label>
                    <FileUploader
                        id="avatarUploader"
                        className="pt-3 pb-3"
                        accept="image/*"
                        name="avatar"
                        randomizeFilename
                        storageRef={this.props.firebase.storage.ref("childImages")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                    />

                    {this.state.isUploading &&
                        <LoadingBar />
                    }

                    {this.state.photo &&
                    <img className="uploaded-image d-block" src={this.state.photo} alt="user avatar" />}
                </div>

                {error &&
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>}


                <button type="submit" className="btn btn-primary" disabled={isInvalid}>Finish</button>
            </form>
        );
    }
}


export default AddChildForm;

const AddChild = compose(
    withFirebase
)(AddChildFormBase);

export { AddChild };
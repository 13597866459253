import React, { Component } from 'react';
import * as _ from 'underscore';
import './ScreenerQuestion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ScreenerQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedAnswer: null,
        };
    }

    componentDidUpdate(previousProps) {
        if (!_.isEqual(this.props.slide, previousProps.slide)) {
            this.setState({
                selectedAnswer: null,
            });
        }
    }

    render() {
        switch(this.props.slide.type) {
            case 'question':
                return this.renderQuestionSlide();
            case 'part':
                return this.renderPartSlide();
            case 'info':
                return this.renderInfoSlide();
            case 'cover':
                return this.renderCoverSlide();
            case 'final':
                return this.renderFinalSlide();
            default:
                return <div></div>
        }
    }



    renderQuestionSlide() {
        return (
            <div className="container-fluid">
                
                {this.props.slide &&
                <div className="row align-items-stretch text-center">
                    <div className="col-12 col-lg-8 bg-light pt-5 pb-5">
                        <div className="d-flex flex-column align-items-center justify-content-center slide-column">
                            {/* IMAGE */}
                            {((this.props.slide.questionType === 'image' && this.props.currentSlideImage !== '') || (this.props.slide.type === 'question' && this.props.slide.image !== '')) &&
                            <img src={this.props.currentSlideImage || this.props.slide.image} alt="question" className="slide-image" />}

                            {/* SLIDE TEXT */}
                            {this.props.slide.questionType !== 'image' &&
                            <p className="question-text">{this.props.slide.text}</p>}

                        </div>
                    </div>

                    <div className="col-12 col-lg-4 bg-primary pt-5 pb-5">
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">

                            {/* INSTRUCTION TEXT */}
                            <p className="instruction-text">{this.props.slide.instruction}</p>

                            {/* QUESTION OPTIONS DROPDOWN */}
                            <p className="answer-drop-label">Child's Response</p>
                            <div className="dropdown">
                                <button className="btn btn-light dropdown-toggle" type="button" id="answerDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Select Answer
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {this.renderAnswerOptions()}
                                </div>
                            </div>

                        </div>
                    </div>

                </div>}
            </div>
        );
    }


    renderCoverSlide() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card pt-5 pb-5">
                            <div className="card-body">
                                <h2 className="slide-header">{this.props.slide.text}</h2>
                                <h3 className="cover-subtext">{this.props.slide.subText}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderInfoSlide() {
        return (
             <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card pt-5 pb-5">
                            <div className="card-body">
                                {this.props.slide.instruction !== "" &&
                                <p className="slide-header">{this.props.slide.instruction}</p>}

                                {this.props.slide.text !== "" &&
                                <p className="slide-header">{this.props.slide.text}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderPartSlide() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card pt-5 pb-5">
                            <div className="card-body">
                                <h2 className="slide-header">{this.props.slide.text}</h2>
                                <h3 className="dmti-section-title">{this.props.slide.subText}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderFinalSlide() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="card pt-5 pb-5">
                            <div className="card-body">
                                <h2 className="slide-header">{this.props.slide.text}</h2>
                                <p>{this.props.slide.subText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderAnswerOptions() {
        let optionsMarkup = [];
        this.props.slide.answerOptions.forEach(option => {
            optionsMarkup.push(
                <button key={this.props.slide.id + option.id} type="button" className="dropdown-item answer-dropdown-button" onClick={() => this.selectAnswer(option.valueRasch, this.props.slide.subCategory, option.text, option.textOverview)}>
                    {option.text === '' ? option.textOverview : option.text}
                    {(option.text === this.state.selectedAnswer || option.textOverview === this.state.selectedAnswer) &&
                    <FontAwesomeIcon className="answer-check" icon="check-circle" />}
                </button>
            );
        });
        return optionsMarkup;
    }


    selectAnswer(valueRasch, subCategory, optionText, optionTextOverview) {
        this.setState({
            selectedAnswer: optionText === '' ? optionTextOverview : optionText
        });
        this.props.selectAnswer(valueRasch, subCategory);
    }
    
}

export default ScreenerQuestion;
import React        from 'react';
import * as ROUTES  from '../../constants/routes';
import SignOutLink  from '../SignOut';
import Avatar       from '../Avatar';

import { compose }          from 'recompose';
import { withFirebase }     from '../Firebase';
import { AuthUserContext }  from '../Session';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';

import './Navigation.scss';
const mainLogoWhite = require('../../images/dmti_logo_white.png');


// Provide the nav bar with the current authUser
const Navigation = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            authUser ? <NavigationAuth authUser={authUser}/> : <NavigationNoAuth />
        )}
    </AuthUserContext.Consumer>
);


class NavigationAuthBase extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true, user: null, menuClass: ''};
        this.toggleNav = this.toggleNav.bind(this);
        this.updateCurrentPage = this.updateCurrentPage.bind(this);
    }

    componentDidMount() {

        let dropdowns = Array.from(document.querySelectorAll('[data-toggle="cc-dropdown"]'));
        dropdowns.forEach(dropdown => {
            dropdown.addEventListener('click', (ev) => {
                const elm = ev.currentTarget;
                if (elm && dropdowns.includes(elm)) {
                    const selector = elm.getAttribute('data-target');
                    this.dropdown(selector);
                }
            })
        }, false);

        let pathArray = window.location.pathname.split('/');
        let currentPage = pathArray[pathArray.length - 1].replace(/.html/g, '');
        let navElement = document.getElementById(currentPage);

        // Check current page and set active list item
        if (navElement) {
            document.getElementById(currentPage).classList.add('active');
        }

        this.props.firebase.getUserById(this.props.authUser.uid).then(response => {
            this.setState({
                user: response,
                loading: false,
                currentPage: currentPage
            });
        }).catch(error => {
            console.error(error);
        });
    }


    /**
     * Updates current page to tell the hamburger menu what link to highlight
     */
    updateCurrentPage() {

        this.toggleNav();
        this.closeAccountDropdown();

        let previousPage = this.state.currentPage;
        document.getElementById(previousPage).classList.remove('active');

        setTimeout(() => {
            let pathArray = window.location.pathname.split('/');
            let currentPage = pathArray[pathArray.length - 1].replace(/.html/g, '');
            let navElement = document.getElementById(currentPage);
            if (navElement) {
                document.getElementById(currentPage).classList.add('active');
            }
            this.setState({currentPage: currentPage});
        }, 1000);
    }


    /**
     * Opens and closes the hamburger menu
     */
    toggleNav() {
        this.closeAccountDropdown();
        if (this.state.menuClass === '') {
            this.setState({menuClass: 'open'});
        }else {
            this.setState({menuClass: ''});
        }
    }


    /**
     * Close the hamburger menu if it is open
     */
    closeNav() {
        if (this.state.menuClass === 'open') {
            this.setState({menuClass: ''});
        }
    }


    /**
     * Hides and shows a dropdown div
     * @param {div} selector 
     */
    dropdown(selector) {
        this.closeNav();
        const targets = Array.from(document.querySelectorAll(selector));
        targets.forEach(target => {
            target.classList.toggle('show');
        });
    }


    /**
     * Close the account dropdown menu
     */
    closeAccountDropdown() {
        let accountDropdown = document.getElementById('accountDropdown');
        if (accountDropdown) {
            if (accountDropdown.classList.contains('show')) {
                accountDropdown.classList.remove('show');
            }
        }
        
    }


    /**
     * Open and close the account dropdown menu
     */
    toggleAccountDropdown() {
        this.dropdown('.cc-dropdown');
    }


    render() {
        return (
            <div>
                <nav className="cc-nav">
                    <div className="cc-nav-inner">
                        <div className="cc-nav-inner">
                            <Link to={ROUTES.DASHBOARD} className="cc-nav-icon">
                                <img src={mainLogoWhite} alt="DMTI Logo" className="nav-DMTI-logo" />
                                <span className="dmti d-none d-md-inline">DMTI</span>
                                <span className="p-portal">Parent Portal</span>
                            </Link>
                        </div>
                        <div className="d-flex align-items-center">
                        
                            {/* Dropdown Button */}
                            <button className="cc-account-toggle" data-toggle="cc-dropdown" data-target=".cc-dropdown">
                                {this.state.user &&
                                <Avatar photoUrl={this.state.user.profileImage} size="sm" border={true}/>}
                            </button>
                            
                            {/* Hamburger Menu - Fontawesome */}
                            {this.state &&
                            <div className="cc-nav-menu-toggle ml-3" onClick={this.toggleNav}>
                                <FontAwesomeIcon icon="bars" />
                            </div>}
                            
                            {/* Dropdown Menu */}
                            <div className="cc-dropdown" id="accountDropdown">
                                <ul>
                                    <li id="account"><Link to={ROUTES.ACCOUNT} onClick={this.toggleAccountDropdown}>Account Settings</Link></li>
                                    <li><SignOutLink onClick={this.toggleAccountDropdown} /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* Sidebar */}
                <div className={'cc-nav-menu ' + this.state.menuClass}>
                    <ul>
                        <li id="home" className="home-link">
                            <Link to={ROUTES.DASHBOARD} >
                                <span className="navMenu-dmti">PARENT PORTAL</span>
                            </Link>
                        </li>
                        <li id="dashboard"><Link to={ROUTES.DASHBOARD} onClick={this.updateCurrentPage}>Dashboard</Link></li>
                        <li id="manage-children"><Link to={ROUTES.MANAGE_CHILDREN} onClick={this.updateCurrentPage}>Manage Children</Link></li>
                        <li id="activities-resources"><Link to={ROUTES.RESOURCES} onClick={this.updateCurrentPage}>Activities & Resources</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}



/**
 * The nav bar to show if user is not signed in
 */
const NavigationNoAuth = () => (
    <nav className="cc-nav">
        <div className="cc-nav-inner">
            <div className="cc-nav-inner">
                <Link to={ROUTES.LANDING} className="cc-nav-icon">
                    <img src={mainLogoWhite} alt="DMTI Logo" className="nav-DMTI-logo" />
                </Link>
            </div>
            <div>
                {/* #####  Hiding temporarily  ###### */}
                {/* <a href="https://mymathsuccess.com/order" className="header-link">
                    Purchase Membership
                </a> */}
            </div>
        </div>
    </nav>
);

const NavigationAuth = compose(
    withRouter,
    withFirebase
)(NavigationAuthBase);

export default Navigation;

export { NavigationAuth };



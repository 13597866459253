import React from 'react';
import ResultDimension from '../ResultDimension';
import ActivityTile from '../../ActivityTile';
import './ChildResult.scss';
import { withFirebase } from '../../Firebase';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';

const URL = process.env.REACT_APP_DRUPAL_URL;

const DIMENSIONS = {
    'PMA-w-2018': {
        sequencing: 'Number Sequencing',
        facts: 'Number Facts',
        relational: 'Relational Thinking',
        contextual: 'Interpreting Context',
        measurement: 'Measurement',
        spatial: 'Spatial Reasoning',
        ext: 'Extras'
    },
    'seq-w-2018': {
        numberidentification: 'Number Identification',
        numberrecognition: 'Number Recognition',
        numbersequences: 'Number Sequences',
        oneonecorrespondence: 'One to One Correspondence',
        quantitydiscrimination: 'Quantity Discrimination'
    },
    'con-w-2018': {
        contextual: 'Contextual',
        compare: 'Compare',
        division: 'Division',
        join: 'Join',
        multiplication: 'Multiplication',
        partwhole: 'Part/Whole',
        separate: 'Separate'
    },
    'rel-w-2018': {
        barmodels: 'Bar Models',
        opennumbersentences: 'Open Number Sentences',
        truefalsenumbersentences: 'True/False Number Sentences',
        visualmodels: 'Visual Models'
    },
    'mea-w-2018': {
        conservationandzeropoint: 'Conservation & Zero Point',
        iteration: 'Iteration',
        partitioning: 'Partitioning',
        transitivity: 'Transitivity'
    },
    'spa-w-2018': {
        Composing: 'Composing',
        Congruence: 'Congruence',
        Rotation: 'Rotation',
        'Rotation and Congruence': 'Rotation & Congruence'
    },
    'flu-w-2018': {
        expressions: 'Expressions',
        additionfacts: 'Addition Facts',
        subtractionfacts: 'Subtraction Facts'
    }
};

const GRADES = {
    'Kindergarten': 'K',
    '1st': '1st',
    '2nd': '2nd',
    'Other': '2nd'
};

const DIAGNOSTICS = {
    'seq-w-2018': 'Number Sequencing',
    'con-w-2018': 'Interpreting Context',
    'rel-w-2018': 'Relational Thinking',
    'mea-w-2018': 'Measurement',
    'spa-w-2018': 'Spatial Reasoning',
    'flu-w-2018': 'Number Facts'
};

class ChildResult extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            scores: null,
            dimensionToImprove: null,
            lowestScore: null,
            resources: null,
            recommendedResources: null
        };
    }


    componentDidMount() {
        this.setState({
            childGrade: GRADES[this.props.child.data.grade]
        });
        this.getResources();
        this.formatResult(this.props.result);
    }


    render() {
        return (
            <div className="child-result">
                {this.props.result.isDiagnostic ? <h2>Diagnostic Results: {DIAGNOSTICS[this.props.result.test]}</h2> : <h2>PMA Screener Results</h2>}

                {this.state.scores &&
                <div>
                    <Link className="link" to={ROUTES.DETAILS + '/' + this.props.child.id + '/' + this.state.scores.id} >View Full Breakdown</Link>
                    <div className="row mt-3">
                        {this.renderGauges()}
                    </div>
                </div>
                }
                {this.state.dimensionToImprove && this.state.lowestScore != null && !this.props.result.isDiagnostic &&
                <div>
                    <h3 className="next-steps">Next Steps</h3>
                    <p className="next-text">
                        {this.props.child.data.name} only scored 
                        <span className="badge score-badge">{this.state.lowestScore}%</span>
                        on <strong>{this.state.dimensionToImprove}</strong>.
                        Try these activities designed to improve that dimension.
                    </p>
                    <div className="row">
                        {this.state.recommendedResources &&
                            this.renderResourceTiles()
                        }
                    </div>
                </div>
                }
            </div>
        );
    }

    //TODO: More shared code. Eventually make a Resource Service
    /**
     *  Retrieve Resources from Drupal endpoint
     */
    getResources() {
        let options = {
            method: "GET",
            headers: {
                "Content-Type": "text/plain"
            },
            mode: 'cors',
        }
        fetch(URL + "/rest/export/json", options).then(res => res.json()).then((resources) => {
            this.setState({resources: resources}, () => this.getRecommendedResources());
        },(error) => {
            console.error(error);
        });
    }



    renderGauges() {
        let gauges = [];
        for (let [key, value] of Object.entries(this.state.scores)) {
            if (this.state.scores.hasOwnProperty(key)) {
                if (key !== 'id') {
                    let score = value.calculated;
                    gauges.push(
                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 text-center" key={key}>
                            <ResultDimension name={DIMENSIONS[key]} score={score} />
                            <p className="result-dimension-label">{DIMENSIONS[this.props.result.test][key]}</p>
                        </div>
                    );
                }
            }
        }
        return gauges;
    }


    //TODO: pull this shared code out of this class and details page


    /**
     * Creates a convenient object to hold all result scores
     * @param {Object} result 
     */
    formatResult(result) {
        let sb = result.scoreBreakDown;
        let scores = {};

        for (let [key, value] of Object.entries(sb)) {
           scores[key] = {
               possible: value.possible,
               earned: value.earned,
               calculated: (value.earned / value.possible) * 100
           }
        }

        for (let [key] of Object.entries(scores)) {
            if (key !== 'id') {
                if (scores[key].calculated % 1 !== 0) {
                    scores[key].calculated = Math.round(scores[key].calculated.toFixed(2));
                }
            }
        }

        scores.id = result.id;

        this.setState({
            scores: scores
        }, () => this.findLowestDimension());
    }



    findLowestDimension() {
        let lowestScore = 100;
        let dimensionToImprove = "";
        for (let [key] of Object.entries(this.state.scores)) {
            if (key !== 'id') {
                let currentScore = this.state.scores[key].calculated;
                if (currentScore < lowestScore) {
                    lowestScore = currentScore;
                    dimensionToImprove = DIMENSIONS[this.props.result.test][key];
                }
            }
        }
        this.setState({ dimensionToImprove: dimensionToImprove, lowestScore: lowestScore }, () => this.getResources());
    }

    getRecommendedResources() {
        let recommendedResources = [];
        
        this.state.resources.forEach(resource => {
            if (resource.field_dimension === this.state.dimensionToImprove && resource.field_file_upload.includes('.pptx') && resource.field_grade === this.state.childGrade) {
                recommendedResources.push(resource);
            }
        });
        this.setState({recommendedResources: recommendedResources});
    }

    renderResourceTiles() {
        let resourceMarkup = [];
        for (let i=0; i<3; i++) {
            resourceMarkup.push(
                <div className="col-12 col-md-6 col-lg-4 pb-4" key={'recommendedActivity-' + i}>
                    <ActivityTile activity={this.state.recommendedResources[i]} />
                </div>
            );
        }
        return resourceMarkup;
    }

}

export default withFirebase(ChildResult);
import React, { Component } from 'react';
import * as _ from 'underscore';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';
import Resource from '../ResourceSection/Resource';
import ActivityTile from '../ActivityTile';
import ImprovementTile from '../ImprovementTile';
import { withRouter } from 'react-router-dom';
import ReactHighcharts from 'react-highcharts';
import { compose } from 'recompose';
import './Details.scss';

const URL = process.env.REACT_APP_DRUPAL_URL;

const DIMENSIONS = {
    'PMA-w-2018': {
        sequencing: 'Number Sequencing',
        facts: 'Number Facts',
        relational: 'Relational Thinking',
        contextual: 'Interpreting Context',
        measurement: 'Measurement',
        spatial: 'Spatial Reasoning',
        ext: 'Extras'
    },
    'seq-w-2018': {
        numberidentification: 'Number Identification',
        numberrecognition: 'Number Recognition',
        numbersequences: 'Number Sequences',
        oneonecorrespondence: 'One to One Correspondence',
        quantitydiscrimination: 'Quantity Discrimination'
    },
    'con-w-2018': {
        contextual: 'Contextual',
        compare: 'Compare',
        division: 'Division',
        join: 'Join',
        multiplication: 'Multiplication',
        partwhole: 'Part/Whole',
        separate: 'Separate'
    },
    'rel-w-2018': {
        barmodels: 'Bar Models',
        opennumbersentences: 'Open Number Sentences',
        truefalsenumbersentences: 'True/False Number Sentences',
        visualmodels: 'Visual Models'
    },
    'mea-w-2018': {
        conservationandzeropoint: 'Conservation & Zero Point',
        iteration: 'Iteration',
        partitioning: 'Partitioning',
        transitivity: 'Transitivity'
    },
    'spa-w-2018': {
        Composing: 'Composing',
        Congruence: 'Congruence',
        Rotation: 'Rotation',
        'Rotation and Congruence': 'Rotation & Congruence'
    },
    'flu-w-2018': {
        expressions: 'Expressions',
        additionfacts: 'Addition Facts',
        subtractionfacts: 'Subtraction Facts'
    }
};

const GRADES = {
    'Kindergarten': 'K',
    '1st': '1st',
    '2nd': '2nd',
    'Other': '2nd'
};

const TEST_PATHS = {
    sequencing: 'seq-w-2018',
    facts: 'flu-w-2018',
    relational: 'rel-w-2018',
    contextual: 'con-w-2018',
    measurement: 'mea-w-2018',
    spatial: 'spa-w-2018'
};

const INITIAL_STATE = {
    scores: null,
    result: null,
    child: null,
    error: null,
    numberToShow: {
        sequencing: 5,
        facts: 5,
        relational: 5,
        contextual: 5,
        measurement: 5,
        spatial: 5
    }
};

const DetailsPage = ({result_id, child_id}) => (
    <AuthUserContext.Consumer>
        {authUser => (
            <Details authUser={authUser} child_id={child_id} result_id={result_id} />
        )}
    </AuthUserContext.Consumer>
);

/**
 * Shows screener results and provides links to resources
 */
class DetailsPageBase extends Component {


    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this._isMounted = true;
        let scores;
        let result;

        this.props.firebase.getResultById(this.props.result_id).then((resultResponse) => {
            if (resultResponse === 'error') {
                this.setState({error: 'there was a problem while retrieving results. please try again' });
            }else {
                scores = this.formatResult(resultResponse);
                result = resultResponse;
                if (this._isMounted) {
                    this.setState({scores: scores, result: result, testType: result.test});
                }
            }
        }).catch(error => {
            console.error(error);
        });

        this.props.firebase.getUserById(this.props.authUser.uid).then(userResult => {
            this.props.firebase.getChildById(this.props.child_id).then(childResult => {
                this.setState({
                    child: childResult,
                    childGrade: GRADES[childResult.grade]
                }, () => {
                    this.getResources();
                });
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }



    /**
     * Creates a Highcharts bar graph to display scores vs. national average
     */
    renderHighcharts() {
        let highchartsConfig = {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
            xAxis: {
                categories: Object.values(DIMENSIONS[this.state.testType]),
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                max: 100,
                title: {
                    text: 'Percentile',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' %'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor: '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{name: this.state.child.name, color: '#00A99D', data: []}, {name: 'National Average', color: '#DDD', data: []}]
        };

       
        for (let [key, value] of Object.entries(this.state.scores)){
            if (key !== 'id') {
                highchartsConfig.series[0].data.push({
                    name: DIMENSIONS[this.state.testType][key],
                    y: value.calculated, //eventually pass in result ID if we want to show individual PMA results. this currently just shows one
                    key: key
                });
                highchartsConfig.series[1].data.push({
                    name: DIMENSIONS[this.state.testType][key],
                    y: 50,  // Currently the hardcoded value for national average
                    key: key
                });
            }
        }
        

        return <ReactHighcharts config={highchartsConfig} callback={() => {}} />
    }

   

    /**
     *  Retrieve Resources from Drupal endpoint
     */
    getResources() {
        let options = {
            method: "GET",
            headers: {
                "Content-Type": "text/plain"
            },
            mode: 'cors',
        }
        fetch(URL + "/rest/export/json", options).then(res => res.json()).then((resources) => {
                this.setState({resources: resources});
                this.createSections(resources);
        },(error) => {
            console.error(error);
        });
    }



    /**
     * Collect each section's resources in its own array.
     * Separate out DMTI resources from the rest.
     */
    createSections(resources) {

        let isFeatured = false;

        let sections = {sequencing: [], facts: [], relational: [], contextual: [], measurement: [], spatial: [], ext: [] };
        let featured = {sequencing: [], facts: [], relational: [], contextual: [], measurement: [], spatial: [] };
        let combined = {sequencing: [], facts: [], relational: [], contextual: [], measurement: [], spatial: [], ext: [] };

        resources.forEach((resource, index) => {

            if (resource.field_grade === this.state.childGrade){
                let tempSectionArray = [];

                if (resource.field_dimension === '') {
                    tempSectionArray = sections;
                    isFeatured = false;
                } else if (resource.field_file_upload.includes('.pptx')) {
                    tempSectionArray = featured;
                    isFeatured = true;
                }else {
                    tempSectionArray = sections;
                    isFeatured = false;
                }
                
                if (resource.field_dimension === '') {
                    tempSectionArray['ext'].push(
                        <div className="col-12 col-lg-4 pb-4" key={resource.nid}>
                            <Resource resource={resource} isFeatured={isFeatured} />
                        </div>
                    );
                }else {
                    if (isFeatured) {
                        tempSectionArray[(_.invert(DIMENSIONS['PMA-w-2018'])[resource.field_dimension])].push(
                            <div className="col-12 col-lg-4 pb-4" key={resource.nid}>
                                <ActivityTile activity={resource} />
                            </div>
                        );
                    }else {
                        tempSectionArray[(_.invert(DIMENSIONS['PMA-w-2018'])[resource.field_dimension])].push(
                            <div className="col-12 col-lg-4 pb-4" key={resource.nid}>
                                <Resource resource={resource} isFeatured={isFeatured} />
                            </div>
                        );
                    }
                }
            }

        });

        for (let [key] of Object.entries(featured)){
            combined[key] = featured[key].concat(sections[key]);
        }

        this.setState({
            resourceSections: sections,
            featuredSections: featured,
            combined: combined
        });
    }


    /**
     * Concatenates the DMTI resource to the front of the rest of the resource
     * and returns the number to show depending on state variable (minimum is 6, max is all).
     * @param {string} type 
     */
    renderResources(type) {
        if (type === 'ext') {
            return this.state.resourceSections[type];
        }
        let resourcesToShow = [];

        for (let i=0; i<=this.state.numberToShow[type]; i++) {
            resourcesToShow.push(this.state.combined[type][i]);
        }
        return resourcesToShow;
    }



    /**
     * Creates a convenient object to hold all result scores
     * @param {Object} result 
     */
    formatResult(result) {
        
        let sb = result.scoreBreakDown;

        let scores = {};

        for (let [key, value] of Object.entries(sb)){
           scores[key] = {
               possible: value.possible,
               earned: value.earned,
               calculated: (value.earned / value.possible) * 100
           }
        }

        for (let [key] of Object.entries(scores)){
            if (key !== 'id') {
                if (scores[key].calculated % 1 !== 0) {
                    scores[key].calculated = Math.round(scores[key].calculated.toFixed(2));
                }
            }
        }
        scores.id = this.props.result_id;

        return scores;
    }



    /**
     * Renders a Bootstrap table to display PMA results.
     */
    renderResultsTable() {
        let data = this.state.scores;
        let tableRows = [];

        for (let [key] of Object.entries(data)){
            if (key !== 'id') {
                tableRows.push(
                    <tr key={key}>
                        <td>{DIMENSIONS[this.state.testType][key]}</td>
                        <td className="text-center">{data[key].calculated}%</td>
                    </tr>
                );
            }
        }

        return (
            <table className="table table-striped">
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        );
    }



    /**
     * Toggles wether or not to show all of a section's resources (the expanded view),
     * or just 6 (the default collapsed view). 
     * @param {string} key The key that corresponds to the quiz dimension
     */
    toggleShowAllResources(key) {
        let numberToShow = {...this.state.numberToShow}
        numberToShow[key] = numberToShow[key] === 5 ? this.state.combined[key].length -1 : 5;
        this.setState({numberToShow}); 
    }




    /**
     * Loops through and renders every complete section
     */
    renderAllSections() {
        if (this.state.testType === 'PMA-w-2018') {
            let sections = [];
            for (let key in DIMENSIONS['PMA-w-2018']) {
                if (DIMENSIONS[this.state.testType].hasOwnProperty(key)) {
                    if (key !== 'ext') {
                        sections.push(
                            <div key={key + '-resources'} className="row mb-4">
                                <div className="col-12">
                                    <div className="card p-4 resource-card">
                                        <h3 className="section-title">{DIMENSIONS['PMA-w-2018'][key]}</h3>
                                        {this.state.combined[key].length > 6 &&
                                        <span className="show-all-link" onClick={() => (this.toggleShowAllResources(key))}>
                                            {this.state.combined[key].length - 1 === this.state.numberToShow[key] ? 'Show less' : 'Show all'}
                                        </span>}
                                        <div className="row">
                                            {this.renderResources(key)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }
            }
            return sections;
        }else {
            let dimension = _.invert(TEST_PATHS)[this.state.testType];
            return (
                <div className="row mb-4">
                    <div className="col-12">
                        <div className="card p-4 resource-card">
                            <h3 className="section-title">{DIMENSIONS['PMA-w-2018'][dimension]}</h3>
                            {this.state.combined[dimension].length > 6 &&
                            <span className="show-all-link" onClick={() => (this.toggleShowAllResources(dimension))}>
                                {this.state.combined[dimension].length - 1 === this.state.numberToShow[dimension] ? 'Show less' : 'Show all'}
                            </span>}
                            <div className="row">
                                {this.renderResources(dimension)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }



    /**
     * Renders all of the improvement tiles for scores that are less than 80.
     */
    renderImprovementSection() {
        let improvementTiles = [];
        
        for (let [key] of Object.entries(this.state.scores)) {
            if (key !== 'id' && this.state.scores[key].calculated < 80) {

                let improvementObject = {
                    dimension: DIMENSIONS['PMA-w-2018'][key],
                    testPath: TEST_PATHS[key],
                    score: this.state.scores[key].calculated,
                    childId: this.props.child_id
                }
                improvementTiles.push(
                    <div className="col-12 col-md-6 col-lg-4 mb-4" key={key + this.props.childId}>
                        <ImprovementTile {...improvementObject} />
                    </div>
                );
            }
        }

        if (improvementTiles.length > 0) {
            return (
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="card p-4">
                            <h2 className="section-title">Areas to focus on</h2>
                            <div className="row">
                                {improvementTiles}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }



    render() {

        return (
            <div className="details_page">

                {this.state.error &&
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>}

                {this.state && this.state.error === null &&
                <div className="container">

                    {this.state.child &&
                    <h1 className="page-header">
                    {this.state.result && this.state.result.isDiagnostic ? 'Diagnostic ' : 'Screener '}
                    Results for {this.state.child.name}</h1>}
                    <div  className="row">
                        {/* BAR GRAPH */}
                        <div className="col-xs-12 col-lg-7 mb-4">
                            <div className="card p-4">
                                <h2 className="section-title">Performance compared to national average</h2>
                                <div>
                                    {this.state.scores && this.state.child &&
                                   this.renderHighcharts()}
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-lg-5 mb-4">
                            <div className="card p-4">
                                <h2 className="section-title">Summary Table</h2>
                                {this.state.scores &&
                                this.renderResultsTable()}
                            </div>
                        </div>
                    </div>

                    {/* IMPROVEMENT SECION */}
                    {this.state.scores && this.state.result && !this.state.result.isDiagnostic &&
                    this.renderImprovementSection()}
                
                    {/* ALL RESOURCES */}
                    <div className="row mt-4">
                        <div className="col-12">
                        <h1 className="page-header">Resources</h1>
                            {this.state.resourceSections && this.state.featuredSections && this.state.combined && this.state.testType &&
                            this.renderAllSections()}
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}


const condition = authUser => !!authUser;

const Details = compose(
    withRouter,
    withFirebase,
)(DetailsPageBase);

export default withAuthorization(condition)(DetailsPage);

export { Details };
export const ACCOUNT = '/account';
export const CHECKOUT = '/checkout';
export const DASHBOARD = '/dashboard';
export const DETAILS = '/details';
export const DIAGNOSTIC = '/diagnostic';
export const LANDING = '/';
export const LOG_IN = '/login';
export const PASSWORD_RESET = '/password-reset';
export const SIGN_UP = '/signup';
export const MANAGE_CHILDREN = '/manage-children';
export const SCREENER = '/screener';
export const RESOURCES = '/activities-resources';
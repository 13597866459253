import React, {Component} from 'react';
import './Login.scss';
import { withFirebase } from '../Firebase';
import { withRouter, Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import { CardElement, injectStripe, Elements } from 'react-stripe-elements';


const INITIAL_STATE = {
    email: '',
    passwordOne: '',
    passwordTwo: '',
    name: '',
    subType: 'annual',
    tokenCreated: false,
    error: null
};

const SignUpPage = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-12">
                <div className="card p-5">
                    <h1>Parent Portal Subscription</h1>
                    <p>Already have an account? <Link to={ROUTES.LOG_IN}>Login</Link></p>
                    <Elements>
                        <AuthUserContext.Consumer>
                            {authUser => (
                                <SignUpForm authUser={authUser}/>
                            )}
                        </AuthUserContext.Consumer>
                    </Elements>
                </div>
            </div>
        </div>
    </div>
);



class SignUpPageBase extends Component {

    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();

        const { email, passwordOne, subType } = this.state;

        this.props.firebase.doCreateUserWithEmailAndPassword(email, passwordOne).then(authUser => {
            // If it has a message, it's an error object
            if (authUser.message) {
                this.setState({error: authUser.message})
            }else {
                let user_id = authUser.user.uid;
                this.props.firebase.doCreateUser(user_id, email, subType).then((res) => {
                    if (res === 'error') {
                        this.setState({error: 'Something went wrong. Please contact DMTI'});
                    }else {
                        this.props.stripe.createToken({name: this.state.name}).then((response) => {
                            if (response.error) {
                                console.error(response.error);
                                this.setState({error: response.error.message});
                            }else {
                                this.props.firebase.addStripeTokenToFirebase(response.token.id, user_id).then((tokResponse) => {
                                    if (tokResponse !== 'error') {
                                       this.setState({tokenCreated: true})
                                    }
                                });
                            }
                        }).catch(error => {
                            this.setState({error: error.message});
                            console.error(error);
                        });
                    }
                });
            }
        });
    }

    setSubType(plan) {
        this.setState({subType: plan});
    }

    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


  render() {

    // If already logged in, go to dashboard
    if (this.props.authUser != null && this.state.tokenCreated) {
        this.props.history.push('/dashboard/new');
    }

    const {
        email,
        passwordOne,
        passwordTwo,
        name,
        subType,
        error
    } = this.state;

    const isInvalid = passwordOne !== passwordTwo || passwordOne === '' || email === '';

    return (
        <form onSubmit={this.onSubmit}>
            <div className="form-group mb-4">
                <label htmlFor="email"></label>
                <input name="email" className="form-control" value={email} onChange={this.onChange} type="email" placeholder="Email Address"></input>
                
                <label htmlFor="passwordOne"></label>
                <input name="passwordOne" className="form-control" value={passwordOne} onChange={this.onChange} type="password" placeholder="Password"></input>
         
                <label htmlFor="passwordTwo"></label>
                <input name="passwordTwo" className="form-control" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Confirm Password"></input>
            </div>

            <div className="form-group mb-4">
                <div className="btn-group w-100" role="group" aria-label="subscription types">
                    <button type="button" className={'btn btn-outline-primary ' + (subType === 'annual' ? 'active' : '')} onClick={() => this.setSubType('annual')}>$59.99 Billed Annually</button>
                    <button type="button" className={'btn btn-outline-primary ' + (subType === 'monthly' ? 'active' : '')} onClick={() => this.setSubType('monthly')}>$5.99 Billed Monthly</button>
                </div>
            </div>

            <h2>Payment Information</h2>

            <div className="form-group">
                <label htmlFor="name"></label>
                <input name="name" className="form-control" value={name} onChange={this.onChange} type="text" placeholder="Full Name (as it appears on your credit card)"></input>
            </div>

            <div className="form-group">
                <CardElement />
            </div>

            <button type="submit" className="btn btn-primary" disabled={isInvalid}>Sign Up</button>

            {error &&
            <div className="alert alert-danger mt-3" role="alert">
                {error}
            </div>}

        </form>
    );
  }
}

const SignUpForm = compose(
    withRouter,
    withFirebase,
    injectStripe
)(SignUpPageBase);

export default SignUpPage;

export { SignUpForm };


import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import * as $ from 'jquery';
import ChildListItem from './ChildListItem';

import './ChildList.scss';


/**
 * Uses User received from props to retrieve and display children
 * @author ccollins
 */
class ChildList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listMarkup: [],
            childToDelete: {
                name: '',
                id: null
            },
            error: ''
        };
        this.openDeleteModal = this.openDeleteModal.bind(this);
    }

    
    componentDidMount() {
        this.renderList(this.props.user.children);
    }


    /**
     * Loops through list of child ID's and calls firebase API to retreive child details.
     * Creates an array of UI list items and adds it to state.
     * @param {Array} children List of child ID's
     */
    renderList(children) {
        let markup = [];

        for (let i = 0; i < children.length; i++) {
            let child_id = children[i];
            // If this causes problems, maybe call firebase directly instead of going through API method.
            this.props.firebase.getChildById(child_id).then((child) => {
                let props = {...child, manage: this.props.manage, openDeleteModal: this.openDeleteModal, childId: child_id};
                markup.push(<ChildListItem {...props} key={child.name}/>);
                this.setState({listMarkup: markup});
            });
        }
    }

    openDeleteModal(id, name) {
        this.setState({
            childToDelete: {
                id: id,
                name: name
            }
        });

        $('#deleteChildModal').modal('show');
    }


    deleteChild(id) {
        this.props.firebase.doDeleteChild(id).then(deleteResponse => {
            if (deleteResponse) {
                this.props.firebase.doRemoveChildFromParent(this.props.userId, this.state.childToDelete.id).then(removeResult => {
                    if (removeResult) {
                        $('#deleteChildModal').modal('hide');
                        this.props.onChildDeleted();
                    } else {
                        this.setState({
                            error: 'There was an error deleting the child. Please refresh and try again'
                        });
                    }
                });
            } else {
                this.setState({
                    error: 'There was an error deleting the child. Please refresh and try again'
                });
            }
        });
    }


    render() {
        return (
           <div>
                <div className="list-group">
                    <div className="list-group-item child-list-header">
                        <div className="row">
                            <div className="col d-none d-md-flex"><span>Photo</span></div>
                            <div className="col"><span>Name</span></div>
                            <div className="col text-center"><span>Grade</span></div>
                            <div className="col justify-content-center d-none d-md-flex"><span>Has results</span></div>
                            {this.props.manage === true &&
                            <div className="col text-center">Delete</div>}
                        </div>
                    </div>
                    {(this.state.listMarkup.length === this.props.user.children.length) &&
                    this.state.listMarkup}
                </div>

                {this.state.error &&
                <div className="alert alert-danger" role="alert">
                    {this.state.error}
                </div>}

                <div className="modal fade" id="deleteChildModal" tabIndex="-1" role="dialog" aria-labelledby="deleteChildModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteChildModalTitle">Delete Child</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this.state.childToDelete &&
                                <p>Are you sure you want to delete <strong>{this.state.childToDelete.name}?</strong></p>}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" data-target="#deleteChildModal">Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={() => {this.deleteChild(this.state.childToDelete.id)}}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        );
    }
}

export default withFirebase(ChildList);
import React from 'react';
import './ResultDimension.scss';


class ResultDimension extends React.Component {

    constructor(props) {
        super(props);
        this.state = {score: null, name: null};
    }

    getScoreColor(score) {
        
        let color = '#E84757';
        let textColor = '#FFF'

        if (score >= 20) {
            color = '#E8794A';
        }
        if (score >= 40 ) {
            color = '#F6CC34';
            textColor = '#985803';
        }
        if (score >= 60) {
            color = '#BADB2E';
            textColor = '#2F8000';
        }
        if (score >= 80) {
            color = '#39BD77';
            textColor = '#FFF';
        }

        return {
            backgroundColor: color,
            color: textColor
        };
    }

    render() {
        return (
            <div>
                <div className="score-color-block" style={this.getScoreColor(this.props.score)}>
                    <span className="align-middle" >{this.props.score}</span>
                </div>
            </div>
        );
    }

}
export default ResultDimension;
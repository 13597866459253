import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap';
import './index.css';
import './main.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';
import {StripeProvider} from 'react-stripe-elements';

/*
Here we are ensuring Firebase is only instantiated once, and injecting it
using React's Context API into the component tree.
*/
ReactDOM.render(
    <StripeProvider apiKey="pk_live_eWnLBtzbKI1UUmyBIWfGOch6">
        <FirebaseContext.Provider value={new Firebase()}>
            <App />
        </FirebaseContext.Provider>
    </StripeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

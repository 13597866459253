import React, {Component} from 'react';
import './Resource.scss';
import mainLogo from '../../../images/logo--dmtinstitute.png';

const ICONS = {
    activity: require('../../../images/resource-activity.png'),
    game: require('../../../images/resource-game.png'),
    literature: require('../../../images/resource-literature.png'),
    misc: require('../../../images/resource-misc.png'),
};

const URL = process.env.REACT_APP_DRUPAL_URL;

class Resource extends Component {

   /**
     * Removes '<p>' and '</p>' from the beginning and end of body text.
     * @param {String} description 
     */
    truncateDescription(description) {
        if (description.indexOf('<p>') !== -1) {
            description = description.slice(3, description.length - 5);
        }
        if (description.length >= 200) {
            description = description.slice(0, 150);
            description += '...';
        }
        return description;
    }

    /**
     * Use the Resource's 'field_type' property to determine which icon to show.
     * @param {String} field_type 
     */
    getIcon(field_type) {
        if (field_type === '') {
            field_type = 'misc';
        }
        return ICONS[field_type.toLowerCase()]
    }

    /**
     * Checks if field_file_upload contains a powerpoint file
     */
    containsMaterials(filePath) {
        return filePath.includes('.pptx');
    }

    /**
     * Returns the resource card link determined by certain fields in the resource object
     * @param {Resource} resource The resource object
     */
    renderLink(resource) {
        // If it has an excel file, it's the 'websites and apps' card
        if (resource.field_file_upload.includes('.xlsx')) {
            return <a className="link resource-link" href={URL + resource.field_file_upload} target="_blank" rel="noopener noreferrer">SEE MORE</a>;
        // If it has a powerpoint it's a DMTI activity
        } else if (resource.field_type === 'Activity' || resource.field_file_upload.includes('.pptx')){
            return <a className="link resource-link" href={URL + resource.field_file_upload} target="_blank" rel="noopener noreferrer">START ACTIVITY</a>;
        // Otherwise it's probably an Amazon resource
        }else {
            return <a className="link resource-link" href={resource.field_url_anchor_text} target="_blank" rel="noopener noreferrer">VIEW ON AMAZON</a>;
        }
        
    }

    render() {
        let resource = this.props.resource

        return (
            
            <div className="resource-card">
                <div className="card-hdr">
                    {/* ICON */}
                    <div className="icon-wrapper">
                        <img className="icon" src={this.getIcon(resource.field_type)} alt="resource icon" />
                        {(resource.field_type === 'Activity' || resource.field_file_upload.includes('.pptx')) && 
                        <img className="DMTI-resource" src={mainLogo} alt="dmti logo" />}
                    </div>
                    {/* TITLE */}
                    <h3 className="resource-title ml-2 mb-0">{resource.title}</h3>
                </div>
                <div>
                    {/* AUTHOR */}
                    {resource.field_author && 
                    <p className="author">{resource.field_author}</p>}

                    {/* DESCRIPTION */}
                    <p className="desc">{this.truncateDescription(resource.body)}</p>

                    {/* TODO: Handle case where activity has amazon link */}
                    {this.renderLink(resource)}
                </div>
            </div>
            
            
        );
    }
}

export default Resource;
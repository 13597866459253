import React from 'react';
import { withAuthorization, AuthUserContext } from '../Session';
import * as _ from 'underscore';
import ActivityTile from '../ActivityTile';
import Resource from '../ResourceSection/Resource';
import './ResourcePage.scss';

const URL = process.env.REACT_APP_DRUPAL_URL;

const DIMENSIONS = {
    sequencing: 'Number Sequencing',
    facts: 'Number Facts',
    relational: 'Relational Thinking',
    contextual: 'Interpreting Context',
    measurement: 'Measurement',
    spatial: 'Spatial Reasoning',
    ext: 'Extras'
};

const ResourcePage = () => (
    <div className="container">
        <div className="row">
            <div className="col">
                <AuthUserContext.Consumer>
                    {authUser => (
                        <ResourcePageBase authUser={authUser} />
                    )}
                </AuthUserContext.Consumer>
            </div>
        </div>
    </div>
);

const INITIAL_STATE = {
    resources: null,
    currentGrade: 'K'
};

class ResourcePageBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {...INITIAL_STATE};
    }

    componentDidMount() {
        this._isMounted = true;
        this.getResources();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     *  Retrieve Resources from Drupal endpoint
     */
    getResources() {
        let options = {
            method: "GET",
            headers: {
                "Content-Type": "text/plain"
            },
            mode: 'cors',
        }
        fetch(URL + "/rest/export/json", options).then(res => res.json()).then((resources) => {
            if (this._isMounted) {
                this.setState({resources: resources});
            }
            this.createSections();
        },(error) => {
            console.error(error);
        });
    }

    render() {
        
            return (
                <div>
                    <h1 className="page-header">Activities and Resources</h1>

                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label id="K" className="btn grade-btn active" onClick={() => {this.setGrade('K')}}>
                            <input type="radio" name="options" id="option1" autoComplete="off"  value="K" /> Kindergarten
                        </label>
                        <label id="1st" className="btn grade-btn" onClick={() => {this.setGrade('1st')}}>
                            <input type="radio" name="options" id="option2" autoComplete="off" value="1st" /> 1st
                        </label>
                        <label id="2nd" className="btn grade-btn" onClick={() => {this.setGrade('2nd')}}>
                            <input type="radio" name="options" id="option3" autoComplete="off" value="2nd" /> 2nd
                        </label>
                    </div>
                
                {this.state.activities && this.state.affiliateResources && 
                this.renderSections()}
                </div>
            );
       
    }

    createSections() {
        let affiliateResources = {sequencing: [], facts: [], relational: [], contextual: [], measurement: [], spatial: [], ext: [] };
        let activities = {sequencing: [], facts: [], relational: [], contextual: [], measurement: [], spatial: [] };

        let filteredResources = _.filter(this.state.resources, (resource)=>{ return this.checkGrade(resource)});

        filteredResources.forEach((resource) => {

            let tempSectionArray = [];

            if (resource.field_dimension === '') {
                tempSectionArray = affiliateResources;
                tempSectionArray['ext'].push(
                    <div className="col-12 col-lg-4 pb-4" key={resource.nid} >
                        <Resource resource={resource} />
                    </div>
                );
            } else if (resource.field_file_upload.includes('.pptx')) {
                tempSectionArray = activities;
                tempSectionArray[(_.invert(DIMENSIONS)[resource.field_dimension])].push(
                    <div className="col-12 col-lg-3 pb-4" key={resource.nid}>
                        <ActivityTile activity={resource} />
                    </div>
                );
            }else {
                tempSectionArray = affiliateResources;
                tempSectionArray[(_.invert(DIMENSIONS)[resource.field_dimension])].push(
                    <div className="col-12 col-lg-4 pb-4" key={resource.nid} >
                        <Resource resource={resource} />
                    </div>
                );
            }
        });
        if (this._isMounted) {
            this.setState({
                affiliateResources: affiliateResources,
                activities: activities
            });
        }
    }

    renderSections() {
        let markup = [];

        for (let [key, value] of Object.entries(DIMENSIONS)){
            if (key !== 'ext') {
                markup.push(
                    <div key={value}>
                        <h2 className="dimension-name">{value}</h2>
                        <p className="dimension-description">{}</p>
                        {/* TODO: create a description object in /constants/dimensions.js. make the keys the same as DIMENSIONS  */}

                        {this.state.activities[key] && this.state.activities[key].length > 0 &&
                        <div>
                            <h3 className="resource-type">DMTI Activities</h3>
                            <div className="row">{this.state.activities[key]}</div>
                        </div>
                        }

                        {this.state.activities[key] && this.state.affiliateResources[key].length > 0 &&
                        <div>
                            <h3 className="resource-type">Affiliate Resources</h3>
                            <div className="row">{this.state.affiliateResources[key]}</div>
                        </div>
                        }
                    </div>
                );
            }
        }
        return markup;
    }

    setGrade(grade) {
        this.setState({currentGrade: grade, affiliateResources: null, activities: null},
            () => {this.createSections()});
    }

    checkGrade(resource) {
        return resource.field_grade.includes(this.state.currentGrade);
    }

   
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ResourcePage);

export {ResourcePageBase};
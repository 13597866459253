import React, {Component} from 'react';
import {compose} from 'recompose';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import AddChildForm from './AddChildForm';
import ChildList from '../ChildList';
import * as $ from 'jquery';
import { AuthUserContext, withAuthorization } from '../Session';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ManageChildren.scss';


const INITIAL_STATE = {
    user: null,
    error: null
};

const ManageChildrenPage = () => (
    <AuthUserContext.Consumer>
        {authUser => (
            <ManageChildren authUser={authUser} />
        )}
    </AuthUserContext.Consumer>
);


class ManageChildrenPageBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.loadUser = this.loadUser.bind(this);
        this.onChildDeleted = this.onChildDeleted.bind(this);
        this.childWasAdded = this.childWasAdded.bind(this);
    }

    componentDidMount() {
        if (this.state.user === null) {
            this.loadUser();
        }
    }

    loadUser() {
        this.props.firebase.getUserById(this.props.authUser.uid).then(userResponse => {
            this.setState({
                user: userResponse
            });
        })
        .catch(error => {
            console.error(error);
        });
    }

    onChildDeleted() {
        this.props.history.push('/manage-children');
        window.location.reload();
    }

    childWasAdded = () => {
        $('#manage-addChildModal').modal('hide');
        window.location.reload();
    }
  
    render() {
        const user = this.state.user;
        return(
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    
                        <h1 className="page-header">Manage Children</h1>

                        <div className="card manage-students-card">
                            {this.state.user && this.state.user.children.length > 0 &&
                            <ChildList user={user} userId={this.props.authUser.uid} manage={true} onChildDeleted={this.onChildDeleted} />}

                            {this.state.user && this.state.user.children.length === 0 &&
                            <p>You have not added any children to your account</p>}

                            <button type="button" className="btn btn-primary mt-4" data-toggle="modal" data-target="#manage-addChildModal">
                                <FontAwesomeIcon className="mr-2" icon="plus" />
                                Add Child
                            </button>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="manage-addChildModal" tabIndex="-1" role="dialog" aria-labelledby="addChildModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addChildModalTitle">Add Child</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                
                                <AddChildForm onChildAdded={this.childWasAdded} />
                                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

const ManageChildren = compose(
    withRouter,
    withFirebase,
)(ManageChildrenPageBase);

export default withAuthorization(condition)(ManageChildrenPage);

export { ManageChildren };

import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { StripeProvider } from 'react-stripe-elements';
import './App.scss';

import Navigation from '../Navigation';
import LoginPage from '../Login';
import SignUpPage from '../SignUp';
import DiagnosticPage from '../Diagnostic';
import AccountPage from '../Account';
import DashboardPage from '../Dashboard';
import DetailsPage from '../Details';
import CheckoutPage from '../Checkout';
import PasswordResetPage from '../PasswordReset';
import ManageChildrenPage from '../ManageChildren';
import ScreenerPage from '../Screener';
import ResourcePage from '../ResourcePage';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faExclamationCircle, faRocket, faSpinner, faArrowRight, faClipboardList, faBars, faTrashAlt, faPoll, faCheck, faShoppingCart, faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


library.add(faExclamationTriangle, faExclamationCircle, faRocket, faSpinner, faArrowRight, faClipboardList, faBars, faTrashAlt, faPoll, faCheck, faShoppingCart, faPlus, faCheckCircle);


const App = () => (
    <Router>
        <div>
            <Navigation />
            <div className="main-content">
                <Route exact path={ROUTES.LANDING} component={LoginPage} />
                <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                <Route path={ROUTES.LOG_IN} component={LoginPage} />
                <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                <Route path={ROUTES.DASHBOARD + '/:new?'} component={DashboardPage} />
                <Route path={ROUTES.SCREENER + '/:child_id/:test_type/:result_id?'} component={(routerProps) => <ScreenerPage test_type={routerProps.match.params.test_type} child_id={routerProps.match.params.child_id} result_id={routerProps.match.params.result_id} />} />
                <Route path={ROUTES.DETAILS + '/:child_id/:result_id'} component={(routerProps) => <DetailsPage result_id={routerProps.match.params.result_id} child_id={routerProps.match.params.child_id} />} />
                <Route path={ROUTES.DIAGNOSTIC} component={DiagnosticPage} />
                <Route path={ROUTES.CHECKOUT} component={CheckoutPage} />
                <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                <Route path={ROUTES.MANAGE_CHILDREN} component={ManageChildrenPage} />
                <Route path={ROUTES.RESOURCES} component={ResourcePage} />
            </div>
           
        </div>
    </Router>
);

export default withAuthentication(App);
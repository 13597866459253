import React from 'react';
import './Avatar.scss';

const Avatar = ({size, photoUrl, border}) => (
    <div
        className={"avatar " + size}
        style={{
            backgroundImage: `url(${photoUrl})`,
            backgroundPositionX: '50%',
            backgroundPositionY: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            border: border ? '2px solid #FFF' : 'none'
        }}>
    </div>
);

export default Avatar;
import React from 'react';
import Avatar from '../../Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ChildListItem.scss';

const ChildListItem = ({name, image, grade, pastResults, manage, openDeleteModal, childId}) => (
    <div className="list-group-item">
        <div className="row align-items-center">
            <div className="col d-none d-md-flex">
                <Avatar photoUrl={image} size="sm" />
            </div>
            <div className="col">
                <span>{name}</span>
            </div>
            <div className="col text-center">
                <span>{grade}</span>
            </div>
            <div className="col justify-content-center d-none d-md-flex">
                <FontAwesomeIcon icon="poll" color={pastResults.length > 0 ? '#343434' : '#cdcdcd'} />
            </div>
            {manage &&
            <div className="col text-center">
                <div onClick={() => {openDeleteModal(childId, name)}}>
                    <FontAwesomeIcon icon="trash-alt" color="#E84757" className="childList-trash-icon" />
                </div>
            </div>}
        </div>
    </div>
);

export default ChildListItem;
import React, { Component } from 'react';
import './PasswordReset.scss';
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
// import { Link, withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';

const PasswordResetPage = () => (
    <div className="container">
        <div className="row">
            <div className="col-md-6 offset-md-3 col-12">
                <div className="card p-5">
                    <h1>Reset Password</h1>
                    <p className="m-0">
                        Enter the email associated with your account and press the submit button.
                        A link to reset your password will be sent to your inbox.
                    </p>
                    <AuthUserContext.Consumer>
                         {authUser => (
                            <PasswordResetForm authUser={authUser} />
                         )}
                    </AuthUserContext.Consumer>
                </div>
            </div>
        </div>
    </div>
);

const INITIAL_STATE = {
    email: '',
    error: null
};

class PasswordResetPageBase extends Component {
    
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    /**
     * Use Firebase API to reset password
     */
    resetPassword(event) {
  
        this.props.firebase.doPasswordReset(this.state.email).then(() => {
            window.alert('An email with a reset password link has been sent to ' + this.state.email + '.');
        })
        .catch(error => {
            this.setState({ error });
           
        });
        event.preventDefault();
    }

    /**
     * Change handler for form fields
     */
    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }
   

    render() {
        
        const { email, error } = this.state;
        const isInvalid = email === '';

        return(

            <form onSubmit={(event) => {this.resetPassword(event)}}>
                <div className="form-group">
                    <label htmlFor="email" type="hidden"></label>
                    <input name="email" className="form-control" value={email} onChange={this.onChange} type="text" placeholder="Email Address" />
                </div>

                <button type="submit" className="btn btn-primary mt-2" disabled={isInvalid}>Submit</button>

                {!this.props.authUser &&
                <Link to={ROUTES.LOG_IN} className="login-link">Go to Login</Link>}
               
                {error &&
                <div className="alert alert-danger" role="alert">
                    {error.message}
                </div>}
                
            </form>
        );
    }
}


const PasswordResetForm = compose(
    // withRouter,
    withFirebase,
)(PasswordResetPageBase);

export default PasswordResetPage;

export { PasswordResetForm };
import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import FileUploader from 'react-firebase-file-uploader';

import ChildList from '../ChildList';
import * as ROUTES from '../../constants/routes';

import './Account.scss';



const AccountPage = () => (
    <div className="container">
        <div className="row">
            <div className="col">
            <h1 className="page-header">Account Details</h1>
                <div className="card account-card">
                    
                    <AuthUserContext.Consumer>
                        {authUser => (
                            <AccountInfo authUser={authUser} />
                        )}
                    </AuthUserContext.Consumer>
                </div>
            </div>
        </div>
    </div>
);


class AccountPageBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            children: null,
            subBadgeClass: 'badge-secondary',
            subBadgeText: 'inactive',
            newPhotoUrl: ''
        };
    }

    componentDidMount() {
        this.loadUser();
    }


    /**
     * Get current user information from Firebase
     */
    loadUser() {
        this.props.firebase.getUserById(this.props.authUser.uid).then(userResponse => {
            let subStatus = userResponse.subscription === 'true';
            if (subStatus) {
                this.setState({
                    user: userResponse,
                    subBadgeClass: 'badge-success',
                    subBadgeText: 'active'
                });
            }else {
                this.setState({
                    user: userResponse
                });
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    deleteAccount() {
        this.props.firebase.doDeleteUser(this.props.authUser.uid);
    }


    ///////////  File Uploader Handlers ///////////

    handleUploadStart = () => this.setState({
        isUploading: true,
        progress: 0
    });
    handleProgress = progress => {
        this.setState({
            progress: progress
        });
    }
    handleUploadError = error => {
        this.setState({
            isUploading: false,
            error: error.message
        });
    };
    handleUploadSuccess = filename => {
        this.setState({
            progress: 100,
            isUploading: false,
        });
        this.props.firebase.uploadPhoto(filename).then(url => {
            if (url === 'error') {
                this.setState({
                    error: 'There was a problem uploading the photo. Please refresh and try again.'
                });
            }else {
                this.setState({
                    newPhotoUrl: url
                });
            }
        });
    };


    /**
     * Save the new profile photo url to User info in Firebase
     */
    saveProfilePhoto = () => {
        this.props.firebase.doUpdateUserPhoto(this.state.newPhotoUrl).then((result) => {
            if (result) {
                this.loadUser();
                window.alert('Your photo has been updated');
            }else {
                this.setState({
                    error: 'There was a problem saving the photo. Please try again'
                });
            }
        });
    }



    render() {
        if (this.state.user) {
            return (
                <div>
                    <div className="row">
                        <div className="col-lg-6 col-12">

                            <h2 className="account-card-header">Your Information</h2>

                            {/* Email */}
                            <div className="account-info-group">
                                <p className="account-info-label">Email</p>
                                <p className="m-0">{this.state.user.email}</p>
                            </div>

                            {/* Subscription Status */}
                             {/* DISABLING THIS FOR NOW. THEY CANNOT BE LOGGED IN WITHOUT A SUBSCRIPTION */}
                            {/* <div className="account-info-group">
                                <p className="account-info-label">Subscription</p>
                                <span className={'badge ' + this.state.subBadgeClass + ' mr-3'}>{this.state.subBadgeText}</span>
                                {this.state.user.subscription === 'false' ?
                                <Link to={ROUTES.CHECKOUT}>Purchase subscription</Link> :
                                <Link to={ROUTES.LANDING}>Cancel Subscription</Link>} 
                            </div> */}
                            {/* TODO: Implement subscription cancellation? */}

                            {/* Password Change */}
                            <div className="account-info-group">
                                <p className="account-info-label mb-2">Password</p>
                                <Link className="reset-button" to={ROUTES.PASSWORD_RESET}>
                                    <button type="button" className="btn btn-primary d-block">Change password</button>
                                </Link>
                            </div>


                            {/* Avatar */}
                            <div className="account-info-group">
                                <p className="account-info-label">Profile image</p>
                                
                                <img src={this.state.user.profileImage} alt="user avatar" className="account-current-avatar" />

                                <button type="button" className="btn btn-primary d-block" data-toggle="modal" data-target="#newPhotoModal">Change image</button>
                            </div>

                            {/* Delete Account */}
                            {/* <div className="account-info-group">
                                <p className="account-info-label">Delete Account</p>
                                <button type="button" className="btn btn-danger d-block" data-toggle="modal" data-target="#deleteAccountModal">Delete account</button>
                            </div> */}
                        </div>

                        {/* Students */}
                        <div className="col-lg-6 col-12">
                            <h2 className="account-card-header">Children</h2>
                            <ChildList user={this.state.user} userId={this.props.authUser.uid} manage={false} />
                            <Link to={ROUTES.MANAGE_CHILDREN}>
                                <button type="button" className="btn btn-primary mt-3">Manage Children</button>
                            </Link>
                        </div>
                    </div>

                    {/* PHOTO UPLOAD MODAL */}
                    <div className="modal fade" id="newPhotoModal" tabIndex="-1" role="dialog" aria-labelledby="newPhotoModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Upload Photo</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <FileUploader
                                        className="pt-3 pb-3"
                                        accept="image/*"
                                        name="avatar"
                                        randomizeFilename
                                        storageRef={this.props.firebase.storage.ref("childImages")}
                                        onUploadStart={this.handleUploadStart}
                                        onUploadError={this.handleUploadError}
                                        onUploadSuccess={this.handleUploadSuccess}
                                        onProgress={this.handleProgress}
                                    />
                                    {this.state.newPhotoUrl !== '' &&
                                    <img src={this.state.newPhotoUrl} alt="user avatar" className="account-current-avatar" />}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-primary" onClick={this.saveProfilePhoto} data-dismiss="modal" data-target="#newPhotoModal" disabled={this.state.newPhotoUrl === ''}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* DELETE ACCOUNT MODAL */}
                    {/* <div className="modal fade" id="deleteAccountModal" tabIndex="-1" role="dialog" aria-labelledby="deleteAccountModal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteAccountModalTitle">Delete Account</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete your account?</p>
                                    <p>Your subscription will be cancelled. Your payment information will be removed from Stripe (our payment handler) and your DMTI account will be permanently deleted along with any children.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" data-target="#deleteChildModal">Cancel</button>
                                    <button type="button" className="btn btn-danger" onClick={() => {this.deleteAccount()}}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            );
        } else {
            return <div></div>;
        }
        
    }
}

const condition = authUser => !!authUser;

const AccountInfo = compose(
    withRouter,
    withFirebase
)(AccountPageBase);

export default withAuthorization(condition)(AccountPage);

export { AccountInfo };
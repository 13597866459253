import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './ImprovementTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ImprovementTile extends Component {

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <p className="improvement-tile-header d-flex justify-content-between">
                        <span>{this.props.dimension}</span>
                        <span className="badge badge-secondary">{this.props.score}%</span>
                    </p>
                    <Link to={ROUTES.SCREENER + '/' + this.props.childId + '/' + this.props.testPath} >
                        <button type="button" className="btn btn-primary">
                            <FontAwesomeIcon icon="rocket" className="mr-2" />
                            Start Diagnostic
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default ImprovementTile;
import React from 'react';
import './ActivityTile.scss';

const URL = process.env.REACT_APP_DRUPAL_URL;

const GRADES = {
    'K': 'Kindergarten',
    '1st': '1st Grade',
    '2nd': '2nd Grade',
};

class ActivityTile extends React.Component {

    white_logo = require('../../images/dmti_logo_white.png');

    componentDidMount() {
    }

    render() {
        return (
            <div className="activity-tile">
                <div className="activity-hdr-wrap">
                    <img src={this.white_logo} alt="dmti logo" className="dmti-logo-activity" />
                    <h4>DMTI ACTIVITY</h4>
                </div>
                {/* <p className="activity-name">{this.props.activity.title} - {GRADES[this.props.activity.field_grade]}</p> */}
                <p className="activity-name">{this.props.activity.title}</p>
                <p className="activity-grade">{GRADES[this.props.activity.field_grade]}</p>
                <a href={URL + this.props.activity.field_file_upload} className="btn activity-btn">START ACTIVITY</a>
            </div>
        );
    }
}

export default ActivityTile;